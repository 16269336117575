import { BoutiqueData } from '@innedit/innedit';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import CMSView from '../../../core/sysext/CMS/components/View';
import params from '../../../core/sysext/CMS/containers/Admin/containers/Boutique/params.json';
import Backoffice from '../../../core/sysext/CMS/containers/Backoffice';
import Form from '../../../core/sysext/CMS/containers/Boutique/components/new/Form';

const PageRecherchesUpdate: FC<PageProps & { id: string }> = props => {
  const { id, location } = props;

  if (!id) {
    return <div>la boutique n existe pas</div>;
  }

  return (
    <Backoffice location={location}>
      <CMSView>
        <Form
          docId={id}
          itemPathnamePrefix="/admin/boutiques/"
          model={new BoutiqueData({ params })}
          name="boutique"
          type="update"
        />
      </CMSView>
    </Backoffice>
  );
};

export default PageRecherchesUpdate;
